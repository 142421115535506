body{
    background-color: black;
    color: whitesmoke;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}

.container .dash{
    height: 290px;
    width: 1010px;
}


.container .dash .painel{
    width: 100%;
    height: 100%;
    align-items: center;
}